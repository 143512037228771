<template>
  <div class="page">
    <div class="c-success c-success_h-800">
      <div class="c-success__body c-success__body_top-208">
        <img src = "@/assets/images/old-alpha/success-logo.png" alt="success-process">
        <h2 class="c-success__title">We’re All <span class="c-success__title_black">Set!</span></h2>
        <div class="c-success__content">
          <p class="c-success__content-text">Welcome to your Sparrow account.</p>
          <p class="c-success__content-text c-success__content-text-sub">
            Please allow our team some time to process and validate your information. In the meantime, you can start exploring the Sparrow platform!
          </p>
        </div>

        <button type="button" class="c-success__submit" name="button" @click='redirectTo'>My Profile</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'SuccessProcess',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    redirectTo() {
      window.location = 'dashboard/#/checker'
    }
  }
}
</script>
